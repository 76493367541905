import React, { Component } from 'react';

import GridIcon from '../../../../images/GridIcon';
import Arrow from '../../../../images/ShortArrow';
import { LinkButton } from '../../../Links/LinkButton';
import { IPostCard, PostCard } from '../../Card';

import style from './CarouselPostGrid.scss';

import cn from 'classnames';
import Flickity from 'react-flickity-component';

interface IProps {
  posts: {
    node: {
      post: IPostCard;
    };
  }[];
  className?: string;
  heading?: string;
  seeAllLink?: string;
}

const flickityOptions = {
  cellAlign: 'left',
  contain: true,
  initialIndex: 1,
  wrapAround: true,
};

type TFlickity = Flickity & {
  // TODO: Remove next line when Ivan's PR will be merged in the library
  off: (eventName: string, listener: (selectedIndex: number) => void) => void;
};

export class CarouselPostGrid extends Component<IProps> {
  flkty: TFlickity;

  state = {
    selectedIndex: flickityOptions.initialIndex,
  };

  componentWillUnmount() {
    this.flkty.off('select', this.handleSelectIndex);
  }

  handleSelectIndex = (selectedIndex: number) => {
    this.setState({ selectedIndex });
  };

  createRef = (flickity: TFlickity) => {
    // The flickity package is not typed well
    // So we cannot use React's useRef or createRef methods because `flickityRef` property
    // has type `(ref: Flickity) => void | undefined`
    // created our own `createRef` to silent eslint error:
    // Lambdas are forbidden in JSX attributes due to their rendering performance impact (jsx-no-lambda)
    this.flkty = flickity;

    this.flkty.on('select', this.handleSelectIndex);
  };

  myCustomNext = () => {
    // You can use Flickity API
    this.flkty.next();
  };

  myCustomPrev = () => {
    // You can use Flickity API
    this.flkty.previous();
  };

  render() {
    const { posts, className, heading = 'Popular Posts', seeAllLink = '/popular' } = this.props;

    return (
      <section className={className}>
        <h2 className="d-md-none">{heading}</h2>
        <div className="row d-none d-md-flex">
          <div className="col d-flex">
            <h3 className="p-0">{heading}</h3>
          </div>
          <div className="col d-flex justify-content-end">
            <LinkButton to={seeAllLink} className={style.link} chevronIcon={false}>
              <GridIcon />
              See All
            </LinkButton>
            <button
              className={style.prevButton}
              onClick={this.myCustomPrev}
              aria-label="previous button"
            >
              <Arrow rotate={180} />
            </button>
            <button
              className={style.nextButton}
              onClick={this.myCustomNext}
              aria-label="next button"
            >
              <Arrow />
            </button>
          </div>
        </div>
        <Flickity
          className={style.carousel}
          options={flickityOptions}
          flickityRef={this.createRef}
          // need `static` to fix console error
          static
        >
          {posts.map(({ node: { post } }) => (
            <div key={post.id} className={cn('col-12 col-md-6', style.postCard)}>
              <PostCard key={post.id} post={post} imageClassName={style.image} />
            </div>
          ))}
        </Flickity>
        <div className="row d-md-none">
          <div className={cn('col', style.col)}>
            <button
              className={style.prevButton}
              onClick={this.myCustomPrev}
              aria-label="previous button"
            >
              <Arrow rotate={180} />
            </button>
          </div>
          <div className={cn('col-auto d-flex justify-content-center', style.col)}>
            <LinkButton to={seeAllLink} chevronIcon={false}>
              See All
            </LinkButton>
          </div>
          <div className={cn('col d-flex justify-content-end', style.col)}>
            <button
              className={style.nextButton}
              onClick={this.myCustomNext}
              aria-label="next button"
            >
              <Arrow />
            </button>
          </div>
        </div>
      </section>
    );
  }
}
