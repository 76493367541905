import React, { CSSProperties } from 'react';

import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import { Hero } from '../components/InnerPages/Hero';
import { IPostCard } from '../components/Post/Card';
import { ProgressBarDesktop } from '../components/ProgressBar/ProgressBarDesktop';
import RenderOnVisible from '../components/RenderOnVisible';
import {
  renderGhostAuthorAside,
  renderGhostChatBubbles,
  renderGhostImageFloat,
  renderGhostInlineTOC,
} from '../utils/ghostSnippets';
import { GhostRelatedArticlesCarousel } from '../utils/GhostSnippets/RelatedArticlesCarousel';
import { GhostSuggestedElements } from '../utils/GhostSnippets/SuggestedElements';
import { optimizeContent } from '../utils/helpers';
import { lazyLoading } from '../utils/lazyLoading';
import trustAllScripts from '../utils/trustAllScripts';

import style from '../styles/pages/page.scss';
import stylePost from '../styles/pages/post.scss';

import cn from 'classnames';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Sticky, StickyContainer } from 'react-sticky';

interface IPageProps {
  data: {
    ghostPage: {
      codeinjection_styles: any;
      title: string;
      html: string;
      feature_image?: string;
      slug: string;
      tags: { name: string; slug: string; visibility: string }[];
    };
    allGhostPost: { edges: { node: IPostCard }[] };
    allGhostAuthor: {
      edges: {
        node: {
          name: string;
          slug: string;
          profile_image: string;
          bio: string;
        };
      }[];
    };
  };
  location: any;
}

/**
 * Single page (/:slug)
 *
 * This file renders a single page and loads all the content.
 *
 */

// RenderOnVisible since content not visible on most mobile screens
// Extracted since onRouteUpdate from gatsby-browser.js runs before mounted
const Content = ({
  content,
  allGhostAuthor,
}: {
  content: string;
  allGhostAuthor: {
    edges: {
      node: {
        name: string;
        slug: string;
        profile_image: string;
        bio: string;
      };
    }[];
  };
}) => {
  React.useEffect(() => {
    trustAllScripts();

    renderGhostImageFloat();

    renderGhostInlineTOC();

    renderGhostChatBubbles(allGhostAuthor.edges);

    renderGhostAuthorAside(allGhostAuthor.edges);

    lazyLoading();
  }, []);

  if (!content) {
    return null;
  }

  return (
    <section
      className="load-external-scripts"
      dangerouslySetInnerHTML={{
        __html: optimizeContent(content),
      }}
    />
  );
};

const Page = ({ data, location }: IPageProps) => {
  const { ghostPage, allGhostAuthor } = data;
  const { codeinjection_styles, feature_image, tags, title, html = '' } = ghostPage;

  React.useEffect(() => {
    tags.push(
      tags.splice(
        tags.findIndex((tag) => tag && tag.slug === 'guest-posts'),
        1
      )[0]
    );

    renderGhostImageFloat();

    renderGhostInlineTOC();

    renderGhostChatBubbles(allGhostAuthor.edges);

    renderGhostAuthorAside(allGhostAuthor.edges);
  }, []);

  const isNoIndex = ghostPage.tags?.reduce((acc, tag) => tag?.name === '#noindex' || acc, false);

  // 1 = mobile, 2 = tablet, 3 = desktop
  const [screenSize, setScreenSize] = React.useState(1);

  const mqls =
    typeof window === 'undefined'
      ? []
      : [
          window.matchMedia('(max-width: 575px)'),
          window.matchMedia('(min-width: 576px) and (max-width: 1023px)'),
          window.matchMedia('(min-width: 1024px)'),
        ];

  function matchMediaQuery(): number {
    let index = -1;
    mqls.some((mql, match) => {
      if (mql.matches) {
        index = match + 1;
      }
    });
    return index;
  }
  function handleMediaQueryListener() {
    const newState = matchMediaQuery();
    if (screenSize !== newState) {
      setScreenSize(newState);
    }
  }

  React.useEffect(() => {
    mqls.forEach((mq) => {
      mq.addEventListener('change', handleMediaQueryListener);
    });
    handleMediaQueryListener();
    return () => {
      mqls.forEach((mq) => {
        mq.removeEventListener('change', handleMediaQueryListener);
      });
    };
  }, []);

  return (
    <>
      <MetaData data={data} location={location} />
      <Helmet>
        {screenSize > 1 && (
          <link rel="preload" href={feature_image} as="image" crossOrigin=""></link>
        )}
        <style type="text/css">{`${codeinjection_styles}`}</style>
        {isNoIndex ? <meta name="robots" content="noindex, nofollow" /> : null}
      </Helmet>
      <Layout headerClassName={style.header} mainClass={style.main} isArticle>
        <Hero
          className={style.hero}
          tag={tags && tags[0] && tags[0].visibility === 'public' ? tags[0] : undefined}
          title={title}
          description=""
        />
        <StickyContainer className={stylePost.sticky}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-3 d-none d-lg-block">
                <Sticky>
                  {({ style, isSticky }: { style: CSSProperties; isSticky: boolean }) => (
                    <div style={{ ...style }}>
                      <RenderOnVisible>
                        <ProgressBarDesktop
                          className={isSticky ? stylePost.stickyPrograssBar : ''}
                          title={title}
                          content={html}
                        />
                      </RenderOnVisible>
                    </div>
                  )}
                </Sticky>
              </div>
              <div
                className={cn('col-12 col-lg-6', style.page, {
                  [style.featureImagePage]: !feature_image,
                })}
              >
                <article>
                  {feature_image ? null : <h1>{title}</h1>}
                  {typeof window !== 'undefined' &&
                    window?.location.pathname !== '/privacy-policy-optin/' &&
                    window?.location.pathname !== '/mobile-terms/' && (
                      <p className={style.disclaimerText}>
                        <Link to="/disclosure/">Click here to read our full disclosure.</Link>
                      </p>
                    )}
                  {/* The main page content */}
                  <Content content={html} allGhostAuthor={allGhostAuthor} />
                  <RenderOnVisible>
                    <div className={style.ghostRelatedArticlesCarousel}>
                      <GhostRelatedArticlesCarousel ghostPost={ghostPage} />
                    </div>
                    <GhostSuggestedElements ghostPost={ghostPage} />
                  </RenderOnVisible>
                </article>
              </div>
            </div>
          </div>
        </StickyContainer>
        <style
          dangerouslySetInnerHTML={{
            __html: `
/*Mediavine Mobile Fix*/
@media only screen and (max-width: 425px) {
  article.col-12.col-lg-8.m-auto {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .page--page--30zjZ > .container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .page--page--30zjZ {
    padding-left: 37px !important;
    padding-right: 37px !important;
  }
}
@media only screen and (max-width: 359px) {
  article.col-12.col-lg-8.post--singleArticle--4N84x {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  article.col-12.col-lg-8.m-auto {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .page--page--30zjZ > .container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .page--page--30zjZ {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
/*Back to Top CSS*/
body.mediavine-videohas-sticky #pe-widget-bell {
  bottom: 300px !important;
}
body.adhesion:not(.mediavine-videohas-sticky) #pe-widget-bell {
  bottom: 120px !important;
}
`,
          }}
        ></style>
      </Layout>
    </>
  );
};

export default Page;

export const postQuery = graphql`
  query($slug: String!) {
    ghostPage(slug: { eq: $slug }) {
      ...GhostPageFields
    }
    allGhostAuthor {
      edges {
        node {
          name
          slug
          bio
          profile_image
        }
      }
    }
  }
`;
