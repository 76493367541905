import React from 'react';

import { IPostCard } from '../../../components/Post/Card';
import { CarouselPostGrid } from '../../../components/Post/Grid/Carousel';

import layoutStyles from '../../../components/InnerPages/Layout.scss';
import stylePost from '../../../styles/pages/post.scss';

import cn from 'classnames';
import { graphql, StaticQuery } from 'gatsby';

const RelatedArticlesCarousel = (props: {
  ghostPost: {
    title: string;
    slug: string;
    tags: {
      slug: string;
      name: string;
    }[];
  };
  data: {
    allGhostPost: any;
    allGhostPage: any;
  };
}) => {
  const { ghostPost, data } = props;
  const { allGhostPost, allGhostPage } = data;
  const minArticlesCount = 3;
  const maxArticlesCount = 5;
  let tagIndex = 0;

  if (typeof document === 'undefined') return null;

  const ghostRelatedArticlesCarousel = document.querySelectorAll(
    '.ghostRelatedArticlesCarousel'
  )[0];
  const relatedArticles = allGhostPost.edges
    .concat(allGhostPage.edges)
    .reduce((acc: { node: { post: IPostCard } }[], curr: { node: IPostCard }) => {
      // skip self or quit when all relatedArticles accumulated
      if (ghostPost.title === curr.node.title || acc.length >= maxArticlesCount) {
        return acc;
      }
      // expected format for CarouselPostGrid
      const foundPost = {
        node: {
          post: curr.node,
        },
      };
      const foundGhostRelatedArticles =
        ghostRelatedArticlesCarousel &&
        ghostRelatedArticlesCarousel.classList.length > minArticlesCount;
      // if related articles specified in ghost
      // find matching articles in allGhostPost
      if (
        foundGhostRelatedArticles &&
        ghostRelatedArticlesCarousel.className.split(' ').indexOf(curr.node.slug) > 0
      ) {
        return [...acc, foundPost];
      } else if (
        // no related articles found in ghost or less than minArticlesCount
        // find default articles matching ghostPost tags
        !foundGhostRelatedArticles &&
        ghostPost?.tags.length > 0 &&
        curr.node?.tags.length > 0
      ) {
        // grab one post per tag
        const matchingTagFound = curr.node.tags.find(
          (tag: { slug: string; name: string }) => tag.slug === ghostPost.tags[tagIndex]?.slug
        );
        // move onto the next tag
        // helps avoid edge case where tags.length === 1 and ghostPost === curr.node
        tagIndex = tagIndex < ghostPost.tags.length - 1 ? tagIndex + 1 : 0;
        if (matchingTagFound) {
          return [...acc, foundPost];
        }
      }
      return acc;
    }, []);

  if (relatedArticles && relatedArticles.length >= minArticlesCount) {
    let renderEl: any = document.getElementById('ghostRelatedArticlesCarousel');

    if (!renderEl) {
      renderEl = ghostRelatedArticlesCarousel;
    }

    return (
      <CarouselPostGrid
        className={cn('m-0', layoutStyles.carouselPostGrid, stylePost.relatedArticles)}
        posts={relatedArticles}
        heading="Related Posts"
        seeAllLink={
          ghostPost.tags && ghostPost.tags[0] ? `/tag/${ghostPost.tags[0].slug}` : '/posts/'
        }
      />
    );
  } else return null;
};

export const GhostRelatedArticlesCarousel = (props: {
  ghostPost: {
    title: string;
    slug: string;
    tags: {
      slug: string;
      name: string;
    }[];
  };
}) => {
  const renderDefaultLayoutSettings = (data: any) => (
    <RelatedArticlesCarousel data={data} {...props} />
  );
  return (
    <StaticQuery
      query={graphql`
        query GhostRelatedQueryCarousel {
          allGhostPost(sort: { order: DESC, fields: published_at }, limit: 200) {
            edges {
              node {
                id
                slug
                title
                published_at
                feature_image
                primary_author {
                  name
                }
                excerpt
                tags {
                  slug
                  name
                }
              }
            }
          }
          allGhostPage(sort: { order: DESC, fields: published_at }, limit: 200) {
            edges {
              node {
                id
                slug
                title
                published_at
                feature_image
                primary_author {
                  name
                }
                excerpt
                tags {
                  slug
                  name
                }
              }
            }
          }
        }
      `}
      render={renderDefaultLayoutSettings}
    />
  );
};
