import React from 'react';

import ChevronIcon from '../../images/Chevron';

import style from './LinkButton.scss';

import cn from 'classnames';

interface ILinkButtonProps {
  to: string;
  className?: string | string[];
  children?: React.ReactNode;
  chevronIcon?: boolean;
}

export const LinkButton = (props: ILinkButtonProps) => {
  const { to, className, children, chevronIcon = true, ...restProps } = props;

  return (
    <a {...restProps} href={to} className={cn(className, style.link)}>
      {children}
      {chevronIcon ? <ChevronIcon /> : ''}
    </a>
  );
};
