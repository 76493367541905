import React from 'react';

const GridIcon = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 6h.01M18 12h.01M18 18h.01M12 6h.01M12 12h.01M12 18h.01M6 6h.01M6 12h.01M6 18h.01"
        stroke="#111"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(GridIcon);
